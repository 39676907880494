<template>
  <div>
    <van-nav-bar
      title="公告详情"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="mNotice" style="margin-bottom: 11px">
      <div class="item">
        <div class="notice-header">
          <!---->
          <div class="name">
            <div class="text" style="color: rgb(0, 0, 0)">
              {{ noticeDetail.contentTitle }}
            </div>
            <div class="time">{{ noticeDetail.datelineCreateReadable }}</div>
          </div>
        </div>
        <div class="title"></div>
        <div class="content">
          <div>
            <p v-html="noticeDetail.contentDetail"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryNewsDetail } from "../../api/news";

export default {
  props: {
    id: {
      type: [Number],
      default: 0,
    },
  },
  data() {
    return {
      noticeDetail: {},
    };
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.getNoticeDetail(); //重新加载数据
    },
  },
  created() {
    console.log(this.id);
    this.getNoticeDetail();
  },
  methods: {
    /**
     * 根据id查询公告详情
     */
    getNoticeDetail() {
      queryNewsDetail({
        id: this.id,
      }).then((response) => {
        this.noticeDetail = response.result;
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.mNotice {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 11px;
}

.mNotice .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 5px;
  background: #fff;
  padding: 16px 11px;
  box-sizing: border-box;
}

.mNotice .item .notice-header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
}

.mNotice .item .notice-header .name {
  flex: 1;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.mNotice .item .notice-header .name .text {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
}

.mNotice .item .notice-header .name .time {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9b9fa8;
}

.mNotice .item .title {
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1e2331;
  margin-top: 13px;
  white-space: pre-line;
}
</style>
